import { Controller } from "@hotwired/stimulus";
import TomSelect from "tom-select";

export default class extends Controller {
    static targets = ['input'];

    connect() {
        new TomSelect(this.inputTarget, {
            placeholder: "Neighboorhoods, Cities & Zips",
            maxItems: null,
            closeAfterSelect: true
        })
    }

}