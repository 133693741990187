import { Controller } from "@hotwired/stimulus"
//import {get } from "@rails/request.js";
import TomSelect from "tom-select"


export default class extends Controller {
    static targets = ['input']

    static values = {
        url: String,
    };

    connect() {

        let url = this.urlValue;
        new TomSelect(this.inputTarget, {
            maxOptions: 10,
            maxItems: 1,
            placeholder: "Search for Contact",
            valueField: 'id',
            labelField: 'name',
            searchField: 'name',
            closeAfterSelect: true,
            load: function(query, callback) {
                if (self.loading > 1) {
                    callback();
                    return;
                }
                var search_url = url + '.json?q=' + encodeURIComponent(query);
                fetch(search_url)
                    .then(response => response.json())
                    .then(json => {
                        callback(json);
                    }).catch(() => {
                        callback();
                    });

            },
            render: {
                option: function(data, escape) {
                    return `<div>${data.name}</div>`;
                },
                item: function(data, escape) {
                    return `<div>${data.name}</div>`;
                }
            },
        })
    }
}