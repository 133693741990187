import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    static targets = ['slack'];

    connect() {
        console.log("test");
        $(this.slackTarget).select2({
            placeholder: 'Rename or Create',
            tags: true
        });
    }

}