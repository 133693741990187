// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction
// 
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    static targets = ["formatted_address", "map", "latitude", "longitude", 'street_number', 'street_name', 'county', 'city', 'zip', 'state', 'place_id']

    initialize() {
        window.initMap = function(...args) {
            const event = document.createEvent("Events")
            event.initEvent("google-maps-callback", true, true)
            event.args = args
            window.dispatchEvent(event)
        }
    }

    connect() {
        if (typeof(google) != "undefined") {
            this.initMap()
        }
    }
    initMap() {
        this.map = new google.maps.Map(this.mapTarget, {
            center: new google.maps.LatLng(this.data.get("latitude") || 39.5, this.data.get("longitude") || -98.35),
            zoom: (this.data.get("latitude") == null ? 4 : 15)
        })

        this.autocomplete = new google.maps.places.Autocomplete(this.formatted_addressTarget, {
            fields: ['address_components', 'formatted_address', 'geometry', 'icon', 'name', 'photo', 'place_id'],
            location: "30.275499105822124,-97.74015737929471",
            radius: 402336,
            componentRestrictions: { country: "us" },
        })
        this.autocomplete.bindTo('bounds', this.map)
        this.autocomplete.addListener('place_changed', this.placeChanged.bind(this))

        this.marker = new google.maps.Marker({
            map: this.map,
            anchorPoint: new google.maps.Point(0, -29)
        })
    }

    get_attribute(place, attribute_name) {
        var attribute = place.address_components.filter(function(x) { return x['types'][0] == attribute_name; })
        return attribute[0]['short_name'];
    }


    placeChanged() {
        let place = this.autocomplete.getPlace()


        if (!place.geometry) {
            window.alert(`No details available for input: ${place.name}`)
            return
        }


        if (place.geometry.viewport) {
            this.map.fitBounds(place.geometry.viewport)
        } else {
            this.map.setCenter(place.geometry.location)
            this.map.setZoom(17)
        }

        this.marker.setPosition(place.geometry.location)
        this.marker.setVisible(true)
        console.log(place)
        this.place_idTarget.value = place.place_id
        this.latitudeTarget.value = place.geometry.location.lat()
        this.longitudeTarget.value = place.geometry.location.lng()
        this.street_numberTarget.value = this.get_attribute(place, 'street_number')
        this.street_nameTarget.value = this.get_attribute(place, 'route')
        this.cityTarget.value = this.get_attribute(place, 'locality')
        this.countyTarget.value = this.get_attribute(place, "administrative_area_level_2")
        this.stateTarget.value = this.get_attribute(place, "administrative_area_level_1")
        this.zipTarget.value = this.get_attribute(place, "postal_code")
    }


    keydown(event) {
        if (event.key == "Enter") {
            event.preventDefault()
        }
    }

}